import React, { useEffect, useState } from "react";
import "./style.scss";
import AssetDefaultIcon from "../../../../assets/AssetIcon.svg";
import { AssetType } from "static/types";
import { CURRENCY_FLAGS } from "static/currencies";
import { getAssetIconUrl } from "helpers/functions";
import { sircapApi } from "shared/api/sircap";
import { GetCountryByCode } from "static/countries";

type PropsType = {
  className?: string;
  asset:
    | {
        type: number;
        symbol: string;
        currency?: { code: string };
        image?: string;
      }
    | any;
  imageUrl?: string | null;
  defaultIcon?: string;
  onGetImageUrl?: (url: string) => void;
  onGetBrokenImage?: (message: string) => void;
};
const AssetIcon: React.FC<PropsType> = React.memo(
  ({
    asset,
    className = "",
    defaultIcon = "",
    imageUrl,
    onGetImageUrl,
    onGetBrokenImage,
  }) => {
    const [img, setImg] = useState(defaultIcon);

    useEffect(() => {
      if (
        [AssetType.ETF, AssetType.Stock, AssetType.Fond].includes(asset.type)
      ) {
        if (imageUrl || asset?.image) {
          setImg(imageUrl || asset?.image);
        } else {
          setImg(getAssetIconUrl(asset.symbol));
        }
      } else if (asset.type === AssetType.Currency) {
        setImg(CURRENCY_FLAGS[asset.symbol]);
      } else if ([AssetType.Bond, AssetType.EuroBond].includes(asset.type)) {
        if (imageUrl || asset?.image) {
          setImg(imageUrl || asset.image);
        } else {
          setImg(asset.symbol);
        }
      } else if ([AssetType.StructuredProduct].includes(asset.type)) {
        // @ts-ignore
        let country = asset?.data?.country;
        if (country) {
          setImg(GetCountryByCode(country)?.flag || asset.symbol);
        } else {
          setImg(asset.symbol);
        }
      } else {
        setImg("");
      }
    }, []);

    useEffect(() => {
      if (img && onGetImageUrl) {
        onGetImageUrl(img);
      }
    }, [img]);

    if (asset.type === AssetType.Currency) {
      return (
        <div className="currency-exchange-rate-icons">
          <div className="exchange-rate-first">
            <img src={CURRENCY_FLAGS[asset.symbol]} alt="icon" loading="lazy" />
          </div>
          <div className="exchange-rate-second">
            {/* @ts-ignore */}
            <img
              src={CURRENCY_FLAGS[asset?.currency?.code]}
              alt="icon"
              loading="lazy"
            />
          </div>
        </div>
      );
    }

    if (!img) {
      return (
        <img
          className={className}
          crossOrigin="anonymous"
          src={defaultIcon || AssetDefaultIcon}
          alt="Icon"
        />
      );
    }

    const onError = async (e: any) => {
      // @ts-ignore
      e.target.src = defaultIcon || AssetDefaultIcon;
      const resp = await sircapApi.market.assetIcon(asset.type, asset.symbol)();
      setImg(resp.data);
      onGetBrokenImage?.("broken");
    };

    return (
      <img
        className={className}
        style={{
          // filter: "brightness(0.9)",
          maxWidth: "40px",
          height: "40px",
          borderRadius: "20px",
          ...([AssetType.Bond, AssetType.EuroBond].includes(asset.type)
            ? { objectFit: "cover" }
            : { objectFit: "contain" }),
        }}
        src={img}
        alt="Icon"
        onError={onError}
        loading="lazy"
      />
    );
  },
  (prev, next) => {
    if (prev.asset.symbol === next.asset.symbol) return true;
    else return false;
  }
);

export default AssetIcon;
