import React, { useEffect, useRef, useState } from "react";
import "./styel.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { format, parse, isValid } from "date-fns";
import Loading from "widgets/loading";
import Clock from "../../../assets/ClockBlog.svg";
import Eye from "../../../assets/EyeBlog.svg";
import Calendar from "../../../assets/CalendarBlog.svg";
import Blog from "../../../assets/BlogCard.webp";
import BlogContent from "../../../assets/BlogContent.webp";
import BlogInformation from "../../../assets/BlogInformation.svg";
import GoBack from "../../../assets/GoBackApp.svg";
import PageUp from "../../../assets/PageUp.svg";
import { useStore } from "zustand";
import { marketStore } from "entities/market";
import { getMLValue } from "helpers/functions";
import { sircapApi } from "shared/api/sircap";
// import { useStore } from "zustand";
// import { dashboardStore } from "entities/dashboard";
// import { marketStore } from "entities/market";
// import { getMLValue } from "helpers/functions";
// import { toast } from "react-toastify";

const BlogPage = () => {
  const navigate = useNavigate();
  const { alias } = useParams();
  const { i18n } = useTranslation();
  const { t } = useTranslation("app");
  const { loadBlogByAlias, blogByAlias } = useStore(marketStore);

  useEffect(() => {
    loadBlogByAlias(alias!);
  }, [alias]);

  // TODO set to true when back avalible.
  const [isLoading, setIsLoading] = useState(false);
  const [showPageUp, setShowPageUp] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [styleBtn, setStyleBtn] = useState(false);
  const contentRef = useRef<any>(null);

  const pageUp = () => {
    contentRef.current?.scrollTo({ top: 0, behavior: "smooth" });
  };

  const isScrolledToBottom = (el: HTMLElement) =>
    el.scrollTop + el.clientHeight >= el.scrollHeight - 30;

  useEffect(() => {
    if (contentRef.current) {
      let oldScrollY = contentRef.current.scrollTop;
      const onScroll = () => {
        if (isScrolledToBottom(contentRef.current)) {
          setShowPageUp(true);
          setStyleBtn(true);
        } else {
          setStyleBtn(false);
        }

        if (contentRef.current.scrollTop >= 150) {
          setShowPageUp(true);
        } else {
          setShowPageUp(false);
        }

        oldScrollY = contentRef.current.scrollTop;
      };

      contentRef.current.onscroll = onScroll;

      return () => {
        if (contentRef.current) {
          contentRef.current.onscroll = null;
        }
      };
    }
  }, [contentRef.current, showPageUp]);

  useEffect(() => {
    if (!showPageUp) {
      const timeout = setTimeout(() => setHidden(true), 500); // Apply `display: none` after 0.5s
      return () => clearTimeout(timeout); // Clear timeout on component unmount or state change
    } else {
      setHidden(false); // Reset `display` to "flex" immediately when visible
    }
  }, [showPageUp]);

  if (!blogByAlias) {
    return (
      <div className="blogPage" style={{ paddingTop: "10%" }}>
        <div className="not-found-coming-soon">
          <p>{t("notFound")}</p>
        </div>
      </div>
    );
  }

  const date = parse(blogByAlias.date, "yyyy.MM.dd", new Date());
  const formattedDate = isValid(date)
    ? format(date, "dd.MM.yyyy")
    : "Invalid date";

  const getBlogHtml = () => {
    let html = getMLValue(
      {
        en: blogByAlias.content_en,
        ru: blogByAlias.content_ru,
        am: blogByAlias.content_am,
      },
      i18n.language
    );

    const el = document.createElement("div");
    el.innerHTML = html;

    const iframes = el.querySelectorAll("figure.media iframe");
    iframes.forEach((iframe) => {
      const src = iframe.getAttribute("src");
      if (src?.includes("vimeo")) {
        iframe.setAttribute(
          "src",
          src + "?loop=1&autoplay=1&muted=1&controls=0"
        );
      } else {
      }
    });

    return el.innerHTML;
  };

  return (
    <div className="blogPage">
      <div className="blog-page-go-back">
        <img onClick={() => navigate(-1)} src={GoBack} alt="Go Back" />
      </div>

      <div className="blog-content-wrapper" ref={contentRef}>
        <div className="blogPageHeader">
          <img
            className="blogPageIconAbsolute"
            src={`${sircapApi.baseUrl}/gallery/${blogByAlias.background_image}`}
            alt="icon"
          />
          <img
            className="blogPageIcon"
            src={`${sircapApi.baseUrl}/gallery/${blogByAlias.background_image}`}
            alt="icon"
          />
          <div className="blogPageHeaderLeft">
            <div className="blogCategoryWrapper">
              {blogByAlias.categories.map((category, index) => (
                <span
                  key={category.id}
                  //todo Add
                  // onClick={(e) => {
                  //   onCategoryClick(category.id);
                  // }}
                >
                  {getMLValue(category.name, i18n.language)}
                  {blogByAlias.categories.length - 1 > index && ","}
                </span>
              ))}
            </div>
            <p>{getMLValue(blogByAlias.title, i18n.language)}</p>
            <div>
              <p>
                <img src={Clock} alt="Clock" />
                {blogByAlias.time_to_read} {t("minBlog")}.
              </p>
              <p>
                <img src={Eye} alt="Eye" />
                {blogByAlias.views}
              </p>
              <p>
                <img src={Calendar} alt="Calendar" />
                {formattedDate}
              </p>
            </div>
          </div>
        </div>
        <div className="blogPageSubtitleSection">
          <p className="blogPageSubtitle">
            {getMLValue(blogByAlias.subtitle, i18n.language)}
          </p>
          <div>
            <span>{t("author")}</span>
            <p>{t("teamSiriusCapital")}</p>
          </div>
        </div>

        <div
          className="blogPageContent"
          dangerouslySetInnerHTML={{
            __html: getBlogHtml(),
          }}
        ></div>

        <a
          className="disclaimer-blog-btn"
          href={`${sircapApi.baseUrl}/documents/${getMLValue(
            blogByAlias?.disclaimer_link || {},
            i18n.language
          )}`}
          download
          target="_blank"
        >
          {t("disclaimer2")}
        </a>
      </div>

      <button
        className="page-up"
        onClick={pageUp}
        style={{
          opacity: showPageUp ? 1 : 0,
          display: hidden ? "none" : "flex",
          bottom: showPageUp ? "-80px" : "0",
          background: showPageUp ? "rgba(255, 255, 255, 0.95)" : "white",
          backdropFilter: showPageUp ? "blur(5px)" : "none",
          boxShadow: showPageUp ? "0px 0px 4px 0px #00000040" : "none",
          animationName: styleBtn ? "pageUp" : "",
          animationDuration: styleBtn ? "1.5s" : "",
        }}
      >
        <img
          src={PageUp}
          alt="PageUp"
          style={{
            animationName: styleBtn ? "pageUpImg" : "",
            animationDuration: styleBtn ? "1.5s" : "",
          }}
        />
      </button>
    </div>
  );
};

export default BlogPage;
