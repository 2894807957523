import React, { FC } from "react";
import "./style.scss";
import NotificationIcon from "../notification-icon";

type PropsType = {
  onClick?: () => void;
  img: string;
  title: string;
  info?: string;
  notificationType: string;
};

const OpenChatBtn: FC<PropsType> = ({
  onClick,
  img,
  title,
  info,
  notificationType,
}) => {
  return (
    <div onClick={onClick} className="open-chat-btn">
      <img src={img} alt="icon" />

      <div>
        <p>{title}</p>
        <span>{info}</span>
      </div>
      <NotificationIcon type={notificationType} />
    </div>
  );
};

export default OpenChatBtn;
