import React, { FC } from "react";
import "./style.scss";

type PropsType = {
  image: string;
  link: any;
  title: string;
  download?: boolean;
  onClick?: () => void;
};
const ShareBtn: FC<PropsType> = ({
  image,
  link,
  title,
  download = false,
  onClick,
}) => {
  return (
    <>
      {!onClick ? (
        <a
          className="share-btn"
          href={link}
          data-toogle="tooltip"
          title={title}
          download={download}
          target={"_blank"}
        >
          <img src={image} alt={image} />
        </a>
      ) : (
        <button
          className="share-btn"
          onClick={onClick}
          data-toogle="tooltip"
          title={title}
        >
          <img src={image} alt={image} />
        </button>
      )}
    </>
  );
};

export default ShareBtn;
