import React from "react";
import "./style.scss";
import Icon from "../../../../assets/PDF.svg";
import SigningContractIcon from "../../../../assets/SigningContractIcon.svg";
import { sircapApi } from "shared/api/sircap";
import { useTranslation } from "react-i18next";

type PropsType = {
  file?: string;
  name: string;
  path?: string;
  onClick?: any;
  needSign?: boolean;
  onSign?: any;
};

const ReportItem: React.FC<PropsType> = ({
  name,
  file,
  path,
  onClick,
  needSign = false,
  onSign,
}) => {
  const { t } = useTranslation("app");

  return (
    <>
      <div
        onClick={() => {
          if (onClick) {
            onClick();
          } else {
            window.open(`${sircapApi.baseUrl}/${path}/${file}`, "_blank");
          }
        }}
        className="report-item-container"
      >
        <span>{name}</span>

        <img className="report-download-btn" src={Icon} alt="" />
      </div>
      {needSign && (
        <div
          className="report-sign-contract"
          onClick={(e) => {
            e.preventDefault();
            onSign();
          }}
        >
          <img src={SigningContractIcon} alt="" />
          <a className="">{t("signContract")}</a>
        </div>
      )}
    </>
  );
};

export default ReportItem;
