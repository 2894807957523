import React, { FC, useRef } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getMLValue, MultiLangField } from "helpers/functions";
import { sircapApi } from "shared/api/sircap";
import { PATH_LIST } from "shared/lib/react-router";

interface ICategories {
  id: number;
  name: MultiLangField;
}
type PropsType = {
  title: any;
  subtitle: any;
  categories: ICategories[];
  onCategoryClick: (catId: number) => void;
  image: any;
  date: any;
  alias: any;
  authors: any[];
};
const ResearchCard: FC<PropsType> = ({
  title,
  subtitle,
  categories,
  onCategoryClick,
  image,
  date,
  alias,
  authors,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(PATH_LIST.research(alias));
  };

  return (
    <div
      onClick={handleNavigate}
      style={{
        backgroundImage: `url(${sircapApi.baseUrl}/gallery/${image})`,
      }}
      className="researchCard"
    >
      <div className="researchHeader">
        {categories.map((category) => (
          <p
            key={category.id}
            onClick={(e) => {
              e.stopPropagation();
              onCategoryClick(category.id);
            }}
          >
            {getMLValue(category.name, i18n.language)}
          </p>
        ))}
      </div>
      <div className="researchContent">
        <p>{getMLValue(title, i18n.language)}</p>
        <div className="offcut"></div>
        <span>{getMLValue(subtitle, i18n.language)}</span>
        <div className="bottom-block">
          <p>
            {date} • {authors.map((m: any) => getMLValue(m.name)).join(", ")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResearchCard;
