import { AssetType, OperationType, OrderType } from "./types";

export const SortData = {
  [String(AssetType.Stock)]: [
    { by: "symbol", text: "By symbol" },
    { by: "ask", text: "By price" },
    { by: "name", text: "By name" },
    { by: "change", text: "By change per day" },
  ],
  [String(AssetType.ETF)]: [
    { by: "symbol", text: "By symbol" },
    { by: "ask", text: "By price" },
    { by: "name", text: "By name" },
    { by: "change", text: "By change per day" },
  ],
  [String(AssetType.Fond)]: [
    { by: "symbol", text: "By symbol" },
    { by: "ask", text: "By price" },
    { by: "name", text: "By name" },
    { by: "change", text: "By change per day" },
  ],
  [String(AssetType.Bond)]: [
    { by: "maturity_date", text: "By maturity date" },
    { by: "rating", text: "By reliability rating" },
    { by: "yield", text: "By yield to maturity" },
  ],
  [String(AssetType.EuroBond)]: [
    { by: "maturity_date", text: "By maturity date" },
    { by: "rating", text: "By reliability rating" },
    { by: "yield", text: "By yield to maturity" },
  ],
};

interface IOperation {
  type: OrderType;
  operation: OperationType;
  showInput: boolean;
  with_sirius: boolean;
  title: string;
  info: string;
}

export const OrderTypes = (
  operation: OperationType,
  isCurrency: boolean,
  onlyForLimitOrders: boolean,
  availableOrderTypes?: OrderType[] | undefined
) => {
  let orderTypes: OrderType[] = [];
  if (isCurrency) {
    orderTypes = [OrderType.Market, OrderType.Limit];
  } else if (onlyForLimitOrders) {
    orderTypes = [OrderType.Limit];
  } else {
    orderTypes = availableOrderTypes?.length
      ? availableOrderTypes
      : [OrderType.Market, OrderType.Limit];
  }

  const types: Record<OperationType, IOperation[]> = {
    [OperationType.Buy]: [],
    [OperationType.Sell]: [],
  };

  if (orderTypes.includes(OrderType.Market)) {
    types[OperationType.Buy].push({
      type: OrderType.Market,
      operation: OperationType.Buy,
      showInput: false,
      with_sirius: false,
      title: "Market price",
      info: "Buy at market price",
    });
    types[OperationType.Sell].push({
      type: OrderType.Market,
      operation: OperationType.Sell,
      showInput: false,
      with_sirius: false,
      title: "Market price",
      info: "Sell at market price",
    });
  }

  if (orderTypes.includes(OrderType.Limit)) {
    types[OperationType.Buy].push({
      type: OrderType.Limit,
      operation: OperationType.Buy,
      showInput: true,
      with_sirius: false,
      title: "Limit price",
      info: "Buy for no more than the specified price",
    });
    types[OperationType.Sell].push({
      type: OrderType.Limit,
      operation: OperationType.Sell,
      showInput: true,
      with_sirius: false,
      title: "Limit price",
      info: "Sell for not less than the specified price",
    });
  }

  if (isCurrency) {
    types[OperationType.Buy].push({
      type: OrderType.Market,
      operation: OperationType.Buy,
      showInput: true,
      with_sirius: true,
      title: "Trade with Sirius",
      info: "Execute your trade directly with Sirius",
    });
    types[OperationType.Sell].push({
      type: OrderType.Market,
      operation: OperationType.Sell,
      showInput: true,
      with_sirius: true,
      title: "Trade with Sirius",
      info: "Execute your trade directly with Sirius",
    });
  }

  return types[operation];
};
