import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStore } from "zustand";
import { useDebounce } from "use-debounce";
import { format, parse, Locale } from "date-fns";
import { enUS, ru, hy } from "date-fns/locale";
import { sircapApi } from "shared/api/sircap";
import { PATH_LIST } from "shared/lib/react-router";
import { getMLValue, navigateToAsset } from "helpers/functions";
import { formatMoney, getAvgValue } from "helpers/money";
import { useBlockScrollbar } from "hooks/useBlockScrollbar";
import { IUserBrokerageAccount, dashboardStore } from "entities/dashboard";
import { marketStore } from "entities/market";
import { AssetType, AssetTypes } from "static/types";
import { IFavorites } from "entities/market";
import HorizontalScroll from "../components/horizontal-scroll";
import { TabBtn } from "../components/tab-btn";
import { MarketAsset } from "../components/market-asset";
import PortfolioSection from "../components/portfolio-section";
import StrategyCard from "../components/strategy-card";
import StoriesModal from "../stories";
import ResearchCard from "../components/research-card";
import BlogCard from "../components/blog-card";
import ResearchCategory from "../components/research-category";
import BlogIcon from "../../../assets/BlogCard.webp";
import Filter from "../../../assets/FilterCategories.svg";
import Search from "../../../assets/SearchByCategory.svg";
// import Search from "../../../assets/SearchMarket.svg";
import "./style.scss";
import Loading from "widgets/loading";

export const WhatToBuyPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation("app");
  const [isDataLoading, setIsDataLoading] = useState(true);
  // Main data
  const [whatToBuy, setWhatToBuy] = useState([]);
  const [portfolioList, setPortfolioList] = useState<Record<number, any[]>>({});
  const [favoritesList, setFavoritesList] = useState<Record<number, any[]>>({});
  // UI interactions
  const [story, setStory] = useState<any>(false);
  const [showMore, setShowMore] = useState<boolean[]>([]);
  const [categoryDropdown, setCategoryDropdown] = useState(false);
  const [stuck, setStuck] = useState<boolean | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [activeIndices, setActiveIndices] = useState<number[]>([]);
  // Data
  const {
    user_brokerage_accounts,
    user_non_trading_accounts,
    loadUserAccounts,
    loadAllRates,
  } = useStore(dashboardStore);
  const {
    loadFavorites,
    favorites,
    loadResearch,
    research,
    loadResearchCategories,
    researchCategories,
    loadMembers,
    members,
    isLoading,
    strategies,
    getStrategies,
    activeWtbTab,
    setActiveWtbTab,
    loadBlog,
    blog,
    loadBlogCategories,
    blogCategories,
  } = useStore(marketStore);
  const [filteredResearch, setFilteredResearch] = useState(research);
  const [debouncedValue] = useDebounce(searchValue, 700);
  const dropdownRef = useRef<any>(null);
  useBlockScrollbar(story, [story]);

  const handleTabClick = (id: number) => {
    setActiveWtbTab(id);
    window.scrollTo({ top: 0 });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          sircapApi.market.whatToBuy().then((resp) => {
            setWhatToBuy(resp?.data?.data || []);
            setShowMore((resp?.data?.data || []).map(() => false));
          }),
          getStrategies(),
          loadUserAccounts(),
          loadFavorites(),
          loadResearchCategories(),
          loadMembers(),
          loadBlog([]),
        ]);
        setIsDataLoading(false);
      } catch (error) {
        console.error("Error loading data:", error);
        setIsDataLoading(false);
      }
    };

    fetchData();

    const savedTab = parseInt(localStorage.getItem("activeWtbTab") || "1");
    setActiveWtbTab(savedTab);
  }, []);

  useEffect(() => {
    if (favorites.length) {
      setFavoritesList(() => {
        const list: Record<number, any[]> = {};
        for (const asset of favorites) {
          const type = asset.asset!.type;
          if (!(type in list)) {
            list[type] = [asset];
          } else {
            list[type].push(asset);
          }
        }
        return list;
      });
    }
  }, [favorites]);

  // Handle category dropdown click outside
  const handleClickOutside = (event: MouseEvent) => {
    dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      setCategoryDropdown(false);
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle scroll
  useEffect(() => {
    const handleScroll = () => {
      setStuck(window.scrollY > 104);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Handle search
  useEffect(() => {
    if (searchValue && searchValue.length) {
      setSearchValue(searchValue);
    } else {
      setSearchValue("");
    }
    handleSearch();
  }, [debouncedValue]);

  useEffect(() => {
    setFilteredResearch(research);
  }, [research]);

  // Handle category filter
  useEffect(() => {
    loadResearch(activeIndices);
  }, [activeIndices]);

  const isActive = (index: number) => activeIndices.includes(index);
  const toggleActiveIndex = (index: number) => {
    setSearchValue("");
    setActiveIndices((prev) => {
      return isActive(index)
        ? prev.filter((i) => i !== index)
        : [index, ...prev];
    });
  };

  const handleSearch = () => {
    if (!searchValue.trim()) {
      setFilteredResearch(research);
      return;
    }

    const searchTerm = searchValue.toLowerCase();

    const filtered = research.filter((item) => {
      const combinedTitle = Object.values(item.title).join(" ").toLowerCase();
      const combinedSubTitle = Object.values(item.subtitle)
        .join(" ")
        .toLowerCase();
      // todo
      // const combinedCategories = item.categories[item.id]
      //   .map((i) => item.categories[i].name)
      //   .join(" ")
      //   .toLowerCase();
      // ||  combinedCategories.includes(searchTerm)
      return (
        combinedTitle.includes(searchTerm) ||
        combinedSubTitle.includes(searchTerm)
      );
    });

    if (
      filtered.length !== filteredResearch.length ||
      !filtered.every((item, index) => item === filteredResearch[index])
    ) {
      setFilteredResearch(filtered);
    }
  };
  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const showDetailsPage = () => {
    //? isLoading ? ( <p className="assets-loading">{t("loading")}</p> )
    return (
      <>
        {activeWtbTab === 1 ? (
          <div className="what-to-buy-main wtbm-tops">
            {whatToBuy.length ? (
              whatToBuy.map((section: any, index: number) => (
                <div key={index}>
                  <p className="section-title">{getMLValue(section.title)}</p>

                  {section.list
                    .slice(0, showMore[index] ? section.list.length : 3)
                    .map((asset: any) => (
                      <MarketAsset
                        key={asset.id}
                        onClick={() => {
                          navigateToAsset(asset.type, asset.id, navigate);
                        }}
                        asset={asset}
                        gains={asset.change}
                        gainsPercent={asset.changesPercentage}
                        showGains={[
                          AssetType.Stock,
                          AssetType.ETF,
                          AssetType.Fond,
                        ].includes(asset.type)}
                        about
                        onAboutClick={() =>
                          setStory({
                            asset,
                            info: section.info.find(
                              (s: any) => s.symbol === asset.symbol
                            ),
                          })
                        }
                      />
                    ))}
                  {section.list.length > 3 && (
                    <button
                      onClick={() =>
                        setShowMore(
                          showMore.map((v, i) => (i === index ? !v : v))
                        )
                      }
                    >
                      {showMore[index] ? t("showLess") : t("showMore")}
                    </button>
                  )}
                </div>
              ))
            ) : isLoading ? (
              <p className="assets-loading">{t("loading")}</p>
            ) : (
              <div className="not-found-coming-soon">
                <p>{t("notFound")}</p>
              </div>
            )}
          </div>
        ) : activeWtbTab === 2 ? (
          <div className="what-to-buy-main what-to-buy-main-research">
            <div
              ref={dropdownRef}
              className={
                stuck
                  ? "wtb-search-filter wtb-search-filter-stuck"
                  : "wtb-search-filter"
              }
            >
              <img
                onClick={(e) => {
                  categoryDropdown && setCategoryDropdown(false);
                  handleSearch();
                }}
                className="wtb-search-icon"
                src={Search}
                alt="search"
              />
              <input
                onClick={() => categoryDropdown && setCategoryDropdown(false)}
                type="text"
                placeholder={t("search")}
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                onKeyDown={handleKeyPress}
              />

              <img
                onClick={(e) => {
                  setCategoryDropdown(!categoryDropdown);
                }}
                className="filter-categories"
                style={{
                  background: categoryDropdown
                    ? "rgba(255, 255, 255, 0.9)"
                    : "rgba(255, 255, 255, 0.5)",
                }}
                src={Filter}
                alt="filter"
              />

              {categoryDropdown && (
                <div className="filter-categories-dropdown">
                  {researchCategories.map((i, index) => (
                    <ResearchCategory
                      key={index}
                      onClick={() => toggleActiveIndex(i.id)}
                      name={getMLValue(
                        researchCategories[index].name,
                        i18n.language
                      )}
                      active={isActive(i.id)}
                    />
                  ))}
                </div>
              )}
            </div>

            {filteredResearch.map((item, index) => {
              const locales: Record<string, Locale> = {
                en: enUS,
                ru: ru,
                am: hy,
              };
              const date = parse(item.date, "yyyy.MM.dd", new Date());
              const formattedDate = format(date, "d MMMM yyyy", {
                locale: locales[i18n.language] || enUS,
              });
              return (
                <ResearchCard
                  key={index}
                  title={item.title}
                  subtitle={item.subtitle}
                  categories={item.categories}
                  onCategoryClick={(catId) => {
                    setActiveIndices([catId]);
                  }}
                  date={formattedDate}
                  image={item.background_image}
                  authors={members.filter((m) => item.authors.includes(m.id))}
                  alias={item.alias}
                />
              );
            })}
          </div>
        ) : activeWtbTab === 3 ? (
          <div className="what-to-buy-main what-to-buy-main-blog">
            {blog.map((item, index) => {
              const date = parse(item.date, "yyyy.MM.dd", new Date());
              const formattedDate = format(date, "dd.MM.yyyy");
              return (
                <BlogCard
                  key={index}
                  categories={item.categories}
                  // onCategoryClick={(catId) => {
                  //   setActiveIndices([catId]);
                  // }}
                  onCategoryClick={() => {}}
                  title={item.title}
                  subtitle={item.subtitle}
                  duration={item.time_to_read}
                  views={item.views}
                  date={formattedDate}
                  image={item.background_image}
                  alias={item.alias}
                />
              );
            })}
          </div>
        ) : activeWtbTab === 4 ? (
          <div className="what-to-buy-main">
            {favorites.length ? (
              Object.entries(favoritesList)
                .sort((a, b) => a[1].length - b[1].length)
                .map(([key, list]) => (
                  <PortfolioSection
                    key={key}
                    favorites={list}
                    category={t(AssetTypes[+key as unknown as AssetType])}
                  />
                ))
            ) : isLoading ? (
              <p className="assets-loading">{t("loading")}</p>
            ) : (
              <p className="no-favorites-text">
                {t("startSavingYourFavorites")}
              </p>
            )}
          </div>
        ) : (
          <div className="what-to-buy-main wtbm-strategy">
            {strategies.map(({ id, symbol, currency, data }, i) => (
              <StrategyCard
                id={i + 1}
                key={symbol}
                onClick={() => navigate(PATH_LIST.strategy(id))}
                asset={strategies[i]}
                minPrice={data.minimal_invest}
                risk={data.risk}
                percent={data.annual_yield}
              />
            ))}
          </div>
        )}
      </>
    );
  };

  useEffect(() => {
    if (location.state?.activeTab) {
      setActiveWtbTab(location.state.activeTab);
    }
  }, [location.state]);

  if (isDataLoading) {
    return <Loading />;
  }

  return (
    <div className="what-to-buy">
      <HorizontalScroll className="tab-bar">
        <TabBtn
          text={t("selections")}
          active={activeWtbTab === 1}
          onClick={() => handleTabClick(1)}
        />
        {research.length > 0 && (
          <TabBtn
            text={t("research")}
            active={activeWtbTab === 2}
            onClick={() => handleTabClick(2)}
          />
        )}
        {blog.length > 0 && (
          <TabBtn
            text={t("blog")}
            active={activeWtbTab === 3}
            onClick={() => handleTabClick(3)}
          />
        )}
        <TabBtn
          text={t("myFavorites")}
          active={activeWtbTab === 4}
          onClick={() => handleTabClick(4)}
        />
        {strategies.length > 0 && (
          <TabBtn
            text={t("strategies")}
            active={activeWtbTab === 5}
            onClick={() => handleTabClick(5)}
          />
        )}
      </HorizontalScroll>

      {showDetailsPage()}

      {story && (
        <StoriesModal
          currentStoryIndex={0}
          onAllStoriesEnd={() => setStory(false)}
          whatToBuyStories
          data={story}
        />
      )}
    </div>
  );
};
