import React, { FC, useCallback, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { useStore } from "zustand";
import html2canvas from "html2canvas";
import Button from "shared/ui/button";
import { accountStore } from "entities/account";
import Loading from "widgets/loading";
import DocViewer from "@cyntler/react-doc-viewer";
import { useUserName } from "entities/session/model/sessionModel";
import { b64toBlob, blobToFile, getContractUrl } from "helpers/functions";
import { isMobile } from "react-device-detect";
import Modal from "widgets/auth/modal";
import { toast } from "react-toastify";
//@ts-ignore
import { TypeAnimation } from "react-type-animation";
import { useTranslation } from "react-i18next";
import { sessionModel } from "entities/session";
import Icon from "../../../assets/CloudDownload.png";
import TermsCheckbox from "pages/onboarding/components/terms-checkbox";
import PDFRenderer from "widgets/pdf-renderer";

type PDFProps = {
  uri: string;
};

const PDF: React.FC<PDFProps> = React.memo(
  ({ uri }) => {
    return (
      <DocViewer
        className="doc-viewer"
        documents={[{ uri, fileType: "pdf" }]}
        pluginRenderers={[PDFRenderer]}
        config={{
          header: {
            disableHeader: true,
            disableFileName: true,
          },
          pdfZoom: {
            defaultZoom: isMobile ? 1 : 2,
            zoomJump: 0.2,
          },
          pdfVerticalScrollByDefault: true,
          loadingRenderer: { overrideComponent: () => <Loading /> },
        }}
      />
    );
  },
  (prev, next) => {
    return prev.uri === next.uri;
  }
);

export const SignUserContractPage = () => {
  const userName = useUserName(true);
  const { isLoading, getContract, signContract } = useStore(accountStore);
  const { updateUserSignContract } = useStore(sessionModel.sessionStore);
  const [contractHtml, setContractHtml] = useState<string | null>(null);
  const [disable, setDisabled] = useState(true);
  const [modal, setModal] = useState(false);
  const [isSigned, setIsSigned] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedNew, setIsCheckedNew] = useState(false);
  const { t: err } = useTranslation("errors");
  const { t } = useTranslation("app");
  const [signImage, setSignImage] = useState<
    { data: any; width: number; height: number } | undefined
  >();

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(PATH_LIST.account);
  };

  useEffect(() => {
    getContract().then(({ data, error }) => {
      if (!error) {
        const { file, signed } = data.data;
        setContractHtml(getContractUrl(file));
        setIsSigned(signed);
        setDisabled(false);
      } else {
        setContractHtml(null);
      }
    });
  }, []);

  const scrollToBottomAndCapture = async () => {
    return new Promise<void>((resolve, reject) => {
      const signUserContractMain = document.querySelector("#proxy-renderer");
      if (signUserContractMain) {
        signUserContractMain.scrollTo({
          top: signUserContractMain.scrollHeight,
          behavior: "smooth",
        });
      }

      const pdfPages = document.querySelector(".react-pdf__Document");

      const typeAnimationNode = document.createElement("div");
      pdfPages?.lastChild?.appendChild(typeAnimationNode);

      const root = ReactDOM.createRoot(typeAnimationNode);
      root.render(
        <TypeAnimation
          sequence={[userName!]}
          wrapper="span"
          speed={50}
          className="sign-container"
          repeat={undefined}
          cursor={false}
        />
      );

      setTimeout(async () => {
        resolve();
        //   try {
        //     const signEl = document.querySelector(".sign-container");
        //     if (!signEl) reject("");
        //     const imageData = await html2canvas(signEl as HTMLElement);
        //     const { width, height } = imageData;
        //     const dataUrl = imageData.toDataURL("image/png");
        //     resolve({ data: dataUrl, width, height });
        //   } catch (error) {
        //     console.error("Error capturing image data:", error);
        //     reject("");
        //   }
      }, 1000);
    });
  };

  const onSignContract = useCallback(
    async (pinCode: string) => {
      setModal(false);
      setDisabled(true);
      try {
        // if (signImage) {
        //   const { data, width, height } = signImage;
        //   const blob = b64toBlob(data, "image/png");
        // if (blob) {
        await signContract(
          // blobToFile(blob, "sign.png"),
          pinCode,
          err,
          () => {
            updateUserSignContract(true);
            navigate(PATH_LIST.account);
          }
        );
        // } else {
        //   toast.error("Server Error");
        // }
        // } else {
        //   toast.error("Server Error");
        // }
      } catch (error) {
        toast.error("Server Error");
      } finally {
        navigate(PATH_LIST.account);
        setDisabled(false);
      }
    },
    [signImage]
  );

  const onSignAction = async () => {
    setModal(false);
    setDisabled(true);
    try {
      await scrollToBottomAndCapture();
      // if (imageData) {
      // setSignImage(imageData);
      setModal(true);
      // } else {
      //   toast.error("Server Error");
      // }
    } catch (error) {
      // navigate(PATH_LIST.account);
      toast.error("Server Error");
      setDisabled(false);
    }
  };

  return (
    <div className="sign-user-contract">
      <div className="sign-user-contract-header">
        <img
          className="go-back-portfolio"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <p>{isSigned ? t("contract") : t("signContract")}</p>
        {contractHtml !== null && isSigned && (
          <a className="download-btn" href={contractHtml} target="_blank">
            <img width={22} height={22} src={Icon} />
          </a>
        )}
      </div>
      <div className="sign-user-contract-main">
        {isLoading ? (
          <Loading />
        ) : contractHtml === null ? (
          <div className="not-found-coming-soon">
            <p>{t("notFound")}</p>
          </div>
        ) : (
          <PDF uri={contractHtml} />
        )}

        {!isSigned && (
          <div className="btn-wrapper deal-btn-wrapper contract-sign-btn-wrapper">
            {/* <TermsCheckbox
              smallFont
              term={t("confirmUpcomingTerms")}
              termsOfBrokerageNew={t("termsOfBrokerage")}
              htmlForId="1"
              isChecked={isCheckedNew}
              onChange={() => setIsCheckedNew(!isCheckedNew)}
            /> */}
            <TermsCheckbox
              smallFont
              term={t("scCheckbox")}
              htmlForId="2"
              isChecked={isChecked}
              onChange={() => setIsChecked(!isChecked)}
              termsOfBrokerageNew={t("termsOfBrokerage")}
            />
            <Button
              type="submit"
              className="contract-sign-btn"
              variant="dark"
              onClick={onSignAction}
              disabled={disable || isLoading || !isChecked}
            >
              {t("signContract")}
            </Button>
          </div>
        )}
      </div>

      {modal && (
        <Modal
          customModal="modalLogOut"
          onClick={onSignContract}
          onHide={() => {
            setModal(false);
            setDisabled(false);
          }}
          title={t("signContract")}
          text={t("enterPin")}
          btnText="Sign"
          showSecondBtn={false}
          customOutlet="customOutlet"
          confirmDeal={true}
          isLoading={isLoading}
          dealError={false}
        />
      )}
    </div>
  );
};
