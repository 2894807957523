import React, { FC } from "react";
import "./style.scss";
import Remove from "../../../../assets/RemoveCategory.svg";

type PropsType = {
  name: string;
  active: boolean;
  onClick: () => void;
};
const ResearchCategory: FC<PropsType> = ({ name, active, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={
        active
          ? "research-category research-category-active"
          : "research-category"
      }
    >
      <img src={Remove} alt="remove" />
      <p>{name}</p>
    </button>
  );
};

export default ResearchCategory;
