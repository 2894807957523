import { RegisterEnum, RegisterStep } from "entities/onboard";

export const PATH_PAGE = {
  root: "/",
  auth: {
    root: "/auth",
    login: {
      root: "login",
      password: "password",
      biometric: "biometric",
      forgotPassword: "forgot-password",
      forgotPasswordVerify: "code-verify",
    },
  },
  onboarding: {
    root: "onboarding",
    chooseAccType: "choose-account-type",
    registration: "registration",
    registerPhone: "register-phone",
    verifyPhone: "verify-phone",
    registerEmail: "register-email",
    verifyEmail: "verify-email",
    registerName: "register-name",
    createPassword: "create-password",
    registerGender: "register-gender",
    registerBirthday: "register-birthday",
    registerCitizenship: "register-citizenship",
    signContract: "sign-contract",

    hopeToAchieve: "hope-to-achieve",
    investingExperience: "investing-experience",
    whatDoYouDoForALiving: "for-a-living",
    investingWithSavings: "investing-with-savings",

    chooseDocType: "choose-document-type",
    proofOfAddressAddressPhotoPage: "proof-of-address",
    selfieWithDocumentPage: "selfie-with-document",
    utilityBillPhotoPage: "utility-bill",

    personalAddress: "personal-address",
    termsAndConditions: "terms-and-conditions",
    pinCode: "pin-code",
    accountHasBeenCreated: "account-created",
  },
  app: {
    root: "",
    home: {
      root: "home",
      editRates: "edit-rates",
      portfolio: "portfolio",
    },
    strategy: "strategy/:asset_id",
    invest: "strategy/:asset_id/:operation",
    whatToBuy: {
      root: "what-to-buy",
      research: "research/:alias",
      blog: "blog/:alias",
      // strategy: "strategy/:asset_id",
      // invest: "strategy/:asset_id/:operation",
    },
    market: {
      root: "market",
      search: "asset-search",
      filter: {
        root: "filter",
        createStockFilter: "create-stock-filter",
        createBondFilter: "create-bond-filter",
        filterReview: "filter-review",
      },
      stock: ":asset_id/stock-details",
      // strategy: ":asset_id/strategy-details",
      bond: ":asset_id/bond-details",
      currency: ":asset_id/currency-details",
      calendar: "calendar",
      buy: ":asset_id/buy",
      sell: ":asset_id/sell",
    },
    chat: {
      root: "chat",
      support: "support",
      ai: "ai",
    },
    account: {
      root: "account",
      personal: {
        root: "personal-info",
        verifyEmail: "verify-email",
        changeEmail: "change-email",
        verifyChangedEmail: "verify-email",
        changePhone: "change-phone-number",
        verifyChangedPhone: "verify-phone-number",
      },

      citizenship: "citizenship",
      identityVerification: "identity-verification",
      registerGender: "register-gender",
      registerBirthday: "register-birthday",
      registerCitizenship: "register-citizenship",
      chooseDocType: "choose-document-type",
      proofOfAddressAddressPhotoPage: "proof-of-address",
      selfieWithDocumentPage: "selfie-with-document",
      utilityBillPhotoPage: "utility-bill",
      personalAddress: "personal-address",

      signBusinessContract: "sign-business-contract",

      questions: "questions",

      myBonuses: "my-bonuses",

      security: {
        root: "security",
        changePassword: "change-password",
        changePIN: "change-PIN",
        pinCode: "pin-code",
      },
      tariffs: "tariffs",
      deposit: "deposit",
      withdrawal: "withdrawal",
      history: {
        root: "history",
        historySearch: "search",
      },
      brokerageReport: "brokerage-report",
      taxReport: "tax-report",
      signContract: "sign-contract",
    },
  },
  page404: "/404",
};

export const PATH_LIST = {
  home: "/home",
  editRates: "/home/edit-rates",
  portfolio: "/home/portfolio",

  whatToBuy: "/what-to-buy",
  research: (alias: any) => `/what-to-buy/research/${alias}`,
  blog: (alias: any) => `/what-to-buy/blog/${alias}`,

  market: "/market",
  search: "/market/asset-search",
  filter: "/market/filter",
  createStockFilter: "/market/create-stock-filter",
  createBondFilter: "/market/create-bond-filter",
  filterReview: "/market/filter-review",

  stock: (asset_id: any) => `/market/${asset_id}/stock-details`,
  // marketStrategy: (asset_id: any) => `/market/${asset_id}/strategy-details`,
  // marketStrategyInvest: (asset_id: any, operation: string) =>
  //   `/market/strategy/${asset_id}/${operation}`,
  bond: (asset_id: any) => `/market/${asset_id}/bond-details`,
  currency: (asset_id: any) => `/market/${asset_id}/currency-details`,
  buy: (asset_id: any) => `/market/${asset_id}/buy`,
  sell: (asset_id: any) => `/market/${asset_id}/sell`,

  strategy: (asset_id: any) => `/strategy/${asset_id}`,
  strategyInvest: (asset_id: any, operation: string) =>
    `/strategy/${asset_id}/${operation}`,

  chat: "/chat",
  chatAI: "/chat/ai",
  support: "/chat/support",

  account: "/account",
  personal: "/account/personal-info",
  verifyEmail: "/account/verify-email",
  changeEmail: "/account/change-email",
  verifyChangedEmail: "/account/verify-email",
  changePhone: "/account/change-phone-number",
  verifyChangedPhone: "/account/verify-phone-number",

  citizenship: "/account/citizenship",
  identityVerification: "/account/identity-verification",
  registerGender: "/account/register-gender",
  registerBirthday: "/account/register-birthday",
  registerCitizenship: "/account/register-citizenship",
  chooseDocType: "/account/choose-document-type",
  selfieWithDocumentPage: "/account/selfie-with-document",
  proofOfAddressAddressPhotoPage: "/account/proof-of-address",
  personalAddress: "/account/personal-address",

  signBusinessContract: "/account/sign-business-contract",

  utilityBillPhotoPage: "/account/utility-bill",

  //!================================================

  questions: "/account/questions",

  myBonuses: "/account/my-bonuses",

  security: "/account/security",
  changePassword: "/account/change-password",
  pinCode: "/account/pin-code",
  changePIN: "/account/change-PIN",
  deposit: "/account/deposit",
  withdrawal: "/account/withdrawal",
  history: "/account/history",
  historySearch: "/account/search",
  brokerageReport: "/account/brokerage-report",
  taxReport: "/account/tax-report",
  signUserContract: "/account/sign-contract",
  tariffs: "/account/tariffs",

  login: "/auth/login",
  loginPassword: "/auth/login/password",
  loginBiometric: "/auth/login/biometric",
  forgotPassword: "/auth/login/forgot-password",
  forgotPasswordVerify: "/auth/login/code-verify",

  chooseAccType: "/onboarding/choose-account-type",
  registration: "/onboarding/registration",
  registerPhone: "/onboarding/register-phone",
  verifyPhone: "/onboarding/verify-phone",
  registerEmail: "/onboarding/register-email",
  // verifyEmail: "/onboarding/verify-email",
  companyName: "/onboarding/your-company",
  registerName: "/onboarding/register-name",
  createPassword: "/onboarding/create-password",
  // registerGender: "/onboarding/register-gender",
  // registerBirthday: "/onboarding/register-birthday",
  // registerCitizenship: "/onboarding/register-citizenship",
  hopeToAchieve: "/onboarding/hope-to-achieve",
  investingExperience: "/onboarding/investing-experience",
  whatDoYouDoForALiving: "/onboarding/for-a-living",
  investingWithSavings: "/onboarding/investing-with-savings",
  // chooseDocType: "/onboarding/choose-document-type",
  // proofOfAddressAddressPhotoPage: "/onboarding/proof-of-address",
  // selfieWithDocumentPage: "/onboarding/selfie-with-document",
  // utilityBillPhotoPage: "/onboarding/utility-bill",
  // personalAddress: "/onboarding/personal-address",
  // pinCode: "/onboarding/pin-code",
  termsAndConditions: "/onboarding/terms-and-conditions",
  accountHasBeenCreated: "/onboarding/account-created",
  // signContract: "/onboarding/sign-contract",
};

export const OnBoardPathList = {
  [RegisterEnum.Individual]: {
    [PATH_LIST.chooseAccType]: null,
    [PATH_LIST.registerPhone]: 0.5,
    [PATH_LIST.verifyPhone]: 7.5,
    [PATH_LIST.registerEmail]: 15.75,
    [PATH_LIST.verifyEmail]: 21,
    [PATH_LIST.registerName]: 26.25,
    [PATH_LIST.createPassword]: 31.5,
    [PATH_LIST.registerGender]: 36.75,
    [PATH_LIST.registerBirthday]: 42,
    [PATH_LIST.registerCitizenship]: 47.25,
    [PATH_LIST.hopeToAchieve]: 52.5,
    [PATH_LIST.investingExperience]: 57.75,
    [PATH_LIST.whatDoYouDoForALiving]: 63,
    [PATH_LIST.investingWithSavings]: 68.25,
    [PATH_LIST.chooseDocType]: 73.5,
    [PATH_LIST.proofOfAddressAddressPhotoPage]: 84,
    [PATH_LIST.selfieWithDocumentPage]: 88,
    [PATH_LIST.utilityBillPhotoPage]: 91.5,
    [PATH_LIST.personalAddress]: 96,
    [PATH_LIST.termsAndConditions]: 98,
  },

  [RegisterEnum.Business]: {
    [PATH_LIST.chooseAccType]: null,
    [PATH_LIST.registerPhone]: 0,
    [PATH_LIST.verifyPhone]: 14.2,
    [PATH_LIST.registerEmail]: 28.5,
    [PATH_LIST.verifyEmail]: 43,
    [PATH_LIST.registerName]: 57,
    [PATH_LIST.createPassword]: 72,
    [PATH_LIST.signBusinessContract]: 86.2,
    [PATH_LIST.termsAndConditions]: 95,
  },
};

export const RegisterStepPages = {
  [RegisterStep.Phone]: PATH_LIST.registerPhone,
  [RegisterStep.Email]: PATH_LIST.registerEmail,
  [RegisterStep.Name]: PATH_LIST.registerName,
  [RegisterStep.Password]: PATH_LIST.createPassword,
  [RegisterStep.ProfileInfo]: PATH_LIST.registerGender,
  [RegisterStep.PersonalDocument]: PATH_LIST.chooseDocType,
  [RegisterStep.ProofOfAddress]: PATH_LIST.proofOfAddressAddressPhotoPage,
  [RegisterStep.Selfie]: PATH_LIST.selfieWithDocumentPage,
  [RegisterStep.SignContract]: PATH_LIST.signBusinessContract,
  [RegisterStep.UtilityBill]: PATH_LIST.utilityBillPhotoPage,
  [RegisterStep.PersonalAddress]: PATH_LIST.personalAddress,
  [RegisterStep.Agreements]: PATH_LIST.termsAndConditions,
  [RegisterStep.Pin]: PATH_LIST.pinCode,
};
