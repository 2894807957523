import React, { useEffect, useState, useCallback } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import PortfolioSection from "../components/portfolio-section";
import { useStore } from "zustand";
import { IUserBrokerageAccount, dashboardStore } from "entities/dashboard";
import { AssetType, AssetTypes } from "static/types";
import { useTranslation } from "react-i18next";
import { ioRTStore } from "services/rt.socket";
import { marketStore } from "entities/market";

export const PortfolioPage: React.FC = () => {
  const [portfolioList, setPortfolioList] = useState<Record<number, any[]>>({});
  const { t } = useTranslation("app");
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(PATH_LIST.home);
  };

  const {
    user_brokerage_accounts,
    user_non_trading_accounts,
    loadUserAccounts,
    loadAllRates,
  } = useStore(dashboardStore);

  const { exchanges } = useStore(marketStore);
  const { connected, list, subscribe, unsubscribe } = useStore(ioRTStore);

  const getUserBrokerageAccount = useCallback(() => {
    return [
      ...user_brokerage_accounts
        .filter((a) => a.quantity > 0)
        .map((v) => ({ ...v, nonTrading: false })),
      ...user_non_trading_accounts
        .filter((a) => a.quantity > 0)
        .map((v) => ({ ...v, nonTrading: true })),
    ];
  }, [user_brokerage_accounts, user_non_trading_accounts]);

  useEffect(() => {
    loadUserAccounts();
  }, []);

  useEffect(() => {
    const portfolio = getUserBrokerageAccount();
    if (portfolio.length) {
      setPortfolioList(() => {
        const list: Record<number, any[]> = {};
        for (const item of portfolio) {
          const type = item.asset.type;
          if (!(type in list)) {
            list[type] = [item];
          } else {
            list[type].push(item);
          }
        }
        return list;
      });
    }
  }, [getUserBrokerageAccount]);

  useEffect(() => {
    const symbols = (getUserBrokerageAccount() || [])
      .filter((a) => [AssetType.Stock, AssetType.ETF].includes(a.asset.type))
      .map((a) => {
        const ex = a.asset.exchange;
        return ex ? `${ex.symbol}~${a.asset.symbol}` : a.asset.symbol;
      });

    if (!connected) return;

    for (const s of symbols) {
      subscribe(s);
    }

    return () => {
      for (const s of symbols) {
        unsubscribe(s);
      }
    };
  }, [exchanges, getUserBrokerageAccount, connected]);

  const getProfitLoss = (account: IUserBrokerageAccount) => {
    const { asset, price, quantity } = account;

    let info = {
      progress: 0,
      percent: 0,
    };
    if ([AssetType.Stock, AssetType.ETF, AssetType.Fond].includes(asset.type)) {
      const quote = getCurrentPrice(account);
      if (quote) {
        const quotePrice = quote * quantity;
        const buyPrice = price * quantity;
        const i = buyPrice / 100;
        const diff = +(quotePrice - buyPrice);
        info.progress = diff;
        info.percent = diff / i;
      }
      return info;
    } else if ([AssetType.Bond, AssetType.EuroBond].includes(asset.type)) {
      const quote = getCurrentPrice(account);
      if (quote) {
        const quotePrice = quote * quantity;
        const buyPrice = price * quantity;
        const i = buyPrice / 100;
        const diff = +(quotePrice - buyPrice);
        info.progress = diff;
        info.percent = diff / i;
      }
      return info;
    }
    return info;
  };

  const getCurrentPrice = (account: IUserBrokerageAccount) => {
    const { type, bid, ask, data, exchange: ex, symbol } = account.asset;
    if ([AssetType.Stock, AssetType.ETF, AssetType.Fond].includes(type)) {
      const rtPrice = list[ex ? `${ex.symbol}~${symbol}` : symbol];
      const quote = rtPrice
        ? +((rtPrice.bid + rtPrice.ask) / 2).toFixed(2)
        : +((bid + ask) / 2).toFixed(2) || 0;
      return quote;
    } else if (
      [
        AssetType.Bond,
        AssetType.EuroBond,
        AssetType.StructuredProduct,
      ].includes(type)
    ) {
      return (
        parseFloat(
          (((ask + bid) / 2 / 100) * data.nominal + data.ai).toFixed(2)
        ) || 0
      );
    } else {
      return 0;
    }
  };

  const getBondData = (account: IUserBrokerageAccount) => {
    const { type, symbol, data } = account.asset;
    if (
      [
        AssetType.Bond,
        AssetType.EuroBond,
        AssetType.StructuredProduct,
      ].includes(type)
    ) {
      const avg_yield = (data.ask_yield + data.bid_yield) / 2;
      return {
        maturity: data.maturity,
        avg_yield: parseFloat(
          ((avg_yield / 100) * data.nominal + data.ai).toFixed(2)
        ),
      };
    } else {
      return null;
    }
  };

  return (
    <div className="portfolio-container">
      <div className="portfolio-header">
        <img onClick={handleNavigate} src={GoBack} alt="Go Back" />
        <p>{t("portfolio")}</p>
      </div>
      <div className="portfolio-main">
        {Object.entries(portfolioList)
          .sort((a, b) => a[1].length - b[1].length)
          .map(([key, list]) => (
            <PortfolioSection
              key={key}
              data={list}
              getProfitLoss={getProfitLoss}
              getCurrentPrice={getCurrentPrice}
              getBondData={getBondData}
              category={t(AssetTypes[+key as unknown as AssetType])}
            />
          ))}
      </div>
    </div>
  );
};
