import React, { useCallback, useEffect, useState } from "react";
import "./style.scss";
import GoBack from "../../../assets/GoBackApp.svg";
import { useNavigate, useParams } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { ActionBtn } from "../components/action-btn";
import { useStore } from "zustand";
import { marketStore } from "entities/market";
import { AssetType, OperationType } from "static/types";
import { DealsTable, MarketChart } from "widgets/market";
import AssetIcon from "../components/asset-icon";
import useCanAction from "hooks/useCanAction";
import HorizontalScroll from "../components/horizontal-scroll";
import { TabBtn } from "../components/tab-btn";
import DepthChart from "../../../assets/DepthChart.png";
import DepthLines from "../../../assets/DepthLines.png";
import DepthField from "../components/depth-field";
import { useTranslation } from "react-i18next";
import { generalSocketStore } from "services/general.socket";
import SaveToFavBtn from "../components/save-to-favorites-btn";
import useSaveHandler from "hooks/useSaveToFavorites";
import SavedToFavNotification from "../components/saved-to-favorites-notification";

let depthInterval: any = null;
export const CurrencyDetailsPage = () => {
  const { t } = useTranslation("app");
  const [activeTab, setActiveTab] = useState("Depth");
  const { asset_id } = useParams();

  const {
    isLoading,
    assetInfo,
    loadAssetInfo,
    loadAssetChart,
    loadAssetDepth,
    assetDepth,
    addFavorites,
    removeFavorite,
    favorites,
  } = useStore(marketStore);

  const { connected, socket } = useStore(generalSocketStore);

  const action = useCanAction();

  const symbol = assetInfo?.asset?.symbol || "";

  const getAssetInfo = useCallback(() => {
    try {
      const info = assetInfo?.info;
      return info;
    } catch (error) {
      return null;
    }
  }, [assetInfo]);

  const getSellPrice = useCallback(() => {
    try {
      const bidPrice = assetInfo?.asset?.bid;
      return `${+bidPrice!.toFixed(4)} ${assetInfo!.asset!.currency.symbol}`;
    } catch (error) {
      return "";
    }
  }, [assetInfo]);

  const getBuyPrice = useCallback(() => {
    try {
      const askPrice = assetInfo?.asset?.ask;
      return `${+askPrice!.toFixed(4)} ${assetInfo!.asset!.currency.symbol}`;
    } catch (error) {
      return "";
    }
  }, [assetInfo]);

  const getMidPrice = useCallback(() => {
    try {
      const bidPrice = assetInfo?.asset?.bid;
      const askPrice = assetInfo?.asset?.ask;
      const price = (bidPrice! + askPrice!) / 2;
      return `${+price.toFixed(4)} ${assetInfo!.asset!.currency.symbol}`;
    } catch (error) {
      return "";
    }
  }, [assetInfo]);

  const getAssetDayChange = useCallback(() => {
    try {
      const quote = assetInfo?.asset;

      if (quote?.change && quote.changesPercentage) {
        return {
          isLoss: quote!.change < 0,
          percent: `${quote!.changesPercentage}%`,
          amount: `${quote!.change}$`,
        };
      } else {
        return {
          isLoss: false,
          percent: "",
          amount: "",
        };
      }
    } catch (error) {
      return {
        isLoss: false,
        percent: "",
        amount: "",
      };
    }
  }, [assetInfo]);

  useEffect(() => {
    loadAssetInfo(asset_id);
    loadAssetChart(asset_id);
    // loadAssetDepth(asset_id);
  }, []);

  // useEffect(() => {
  //   clearInterval(depthInterval);
  //   loadAssetDepth(asset_id);
  //   depthInterval = setInterval(() => {
  //     loadAssetDepth(asset_id);
  //   }, 4 * 1000);

  //   return () => {
  //     clearInterval(depthInterval);
  //   };
  // }, []);

  useEffect(() => {
    if (!connected || !asset_id) {
      socket.off("update-orderbook");
      return;
    }
    socket.on("update-orderbook", (data) => {
      const { asset_id: as_id, orderbook } = data;
      if (as_id == +asset_id) {
        marketStore.setState({ assetDepth: orderbook });
      }
    });
    socket.emit("subscribe-orderbook", { asset_id });

    return () => {
      socket
        .emit("unsubscribe-orderbook", { asset_id })
        .off("update-orderbook");
    };
  }, [asset_id, connected]);

  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate(-1);
  };
  const handleDeal = (type: number, operation: string) => {
    if (operation === "buy") {
      navigate(PATH_LIST.buy(asset_id!) + `?type=${type}`);
    } else {
      navigate(PATH_LIST.sell(asset_id!) + `?type=${type}`);
    }
  };

  const { saved, showNotification, handleSave } = useSaveHandler(
    +asset_id!,
    addFavorites,
    removeFavorite
  );

  if (isLoading) {
    return (
      <div className={`stock-details stock-details${activeTab}`}>
        <div className="stock-details-header">
          <img
            className="go-back-portfolio"
            onClick={handleNavigate}
            src={GoBack}
            alt="Go Back"
          />
          <div>
            <p>{symbol}</p>
          </div>
        </div>
        <div className="stock-details-main">
          <p className="assets-loading assets-loading-page">{t("loading")}</p>
        </div>
      </div>
    );
  }

  if (!assetInfo) {
    <div className={`stock-details stock-details${activeTab}`}>
      <div className="stock-details-header">
        <img
          className="go-back-portfolio"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <div>
          <p>{symbol}</p>
        </div>
      </div>
      <div className="stock-details-main">
        <div className="not-found-coming-soon">
          <p>{t("notFound")}</p>
        </div>
      </div>
    </div>;
  }

  const scroll = () => {
    const stockDetails = document.querySelector(".stock-details");
    if (stockDetails) {
      stockDetails.scrollTop = 0;
    }
  };
  const handleTabClick = (text: string) => {
    setActiveTab(text);
    scroll();
  };

  return (
    <div className={`stock-details stock-details${activeTab}`}>
      {showNotification && <SavedToFavNotification symbol={symbol} />}

      <div className="stock-details-header">
        <img
          className="go-back-portfolio"
          onClick={handleNavigate}
          src={GoBack}
          alt="Go Back"
        />
        <div>
          <p>{symbol}</p>
          <span>{getAssetInfo()?.companyName}</span>
        </div>

        {/* @ts-ignore */}
        <SaveToFavBtn onclick={() => handleSave(asset_id)} saved={saved} />
      </div>
      <HorizontalScroll className="tab-bar">
        <TabBtn
          text={t("trading")}
          active={activeTab === "Trading"}
          onClick={() => handleTabClick("Trading")}
        />
        <TabBtn
          text={t("depth")}
          active={activeTab === "Depth"}
          onClick={() => handleTabClick("Depth")}
        />
      </HorizontalScroll>

      {activeTab === "Trading" ? (
        <div className="stock-details-main">
          <div className="currency-info-bargaining">
            <AssetIcon
              asset={{
                symbol: symbol!,
                type: AssetType.Currency,
                currency: assetInfo?.asset?.currency,
              }}
            />
            <div>
              <p className="price">{getMidPrice()}</p>
              {getAssetDayChange().percent && (
                <>
                  <div className="gain-percent">
                    <span className={getAssetDayChange().isLoss ? "loss" : ""}>
                      {getAssetDayChange().amount}
                    </span>
                    <span
                      className={
                        "gains-percent " +
                        (getAssetDayChange().isLoss ? "loss" : "")
                      }
                    >
                      {getAssetDayChange().percent}
                    </span>
                  </div>
                  <p>{t("today")}</p>
                </>
              )}
            </div>
          </div>
          <MarketChart onlyLineChart={true} />
          <DealsTable assetId={assetInfo?.asset?.id || undefined} />
        </div>
      ) : (
        <div className="stock-details-main stock-details-main-review">
          <div className="depth-header">
            <p>{t("depthOfMarket")}</p>

            {/* <div>
              <button>
                <img src={DepthLines} alt="depth-lines" />
              </button>
              <button>
                <img src={DepthChart} alt="depth-chart" />
              </button>
            </div> */}
          </div>

          <div className="depth-main">
            <div className="depth-main-header">
              <p>{t("sale")}</p>
              <p>{t("price")}</p>
              <p>{t("purchase")}</p>
            </div>
            <div className="depth-content">
              {assetDepth.map((item, index) => {
                return (
                  <DepthField
                    key={index}
                    purchase={
                      item.operation === "sell" ? item.quantity : undefined
                    }
                    price={item.price}
                    sale={item.operation === "buy" ? item.quantity : undefined}
                    progress={item.progress}
                    currency={assetInfo!.asset!.currency.symbol}
                    onClick={() => {
                      navigate(
                        PATH_LIST[item.operation](asset_id) +
                          `?type=${assetInfo!.asset!.type}&price=${item.price}`
                      );
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}

      <div className="action-btns-wrapper">
        <ActionBtn
          onClick={() =>
            action(() => handleDeal(assetInfo!.asset!.type, OperationType.Sell))
          }
          action={t("sell")}
          price={getSellPrice()}
          disabled={false}
        />
        <ActionBtn
          onClick={() =>
            action(() => handleDeal(assetInfo!.asset!.type, OperationType.Buy))
          }
          action={t("buy")}
          price={getBuyPrice()}
          type="dark"
          disabled={false}
        />
      </div>
    </div>
  );
};
