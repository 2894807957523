export enum EntityType {
  Individual = "individual",
  Entity = "entity",
}

export enum IccContractType {
  AI_Index_Confirmation = 'ai_index_confirmation',
  IPO_Index_Confirmation = 'ipo_index_confirmation',
}

export enum DocumentType {
  Passport = "passport",
  IdCard = "idCard",
  IdCardBack = "idCardBack",
  Selfie = "selfie",
  ProofAddress = "proofOfAddress",
  UtilityBill = "utilityBill",
  Avatar = "avatar",
}

export enum AssetType {
  Stock = 1, // Акция
  PreferredShare = 2, // Привилегерованная Акция
  Bond = 3,
  ADR_GDR = 4, // АДР ГДР
  Currency = 5, // Валюта
  EuroBond = 6, // Еврооблигация
  Fond = 7, // Фонд
  ETF = 8,
  StructuredProduct = 9, // Структурированный Продукт
}

export const AssetTypes: Record<AssetType, string> = {
  [AssetType.Stock]: "Stocks",
  [AssetType.PreferredShare]: "",
  [AssetType.Bond]: "Bonds",
  [AssetType.ADR_GDR]: "",
  [AssetType.Currency]: "Currencies",
  [AssetType.EuroBond]: "Eurobonds",
  [AssetType.Fond]: "Fonds",
  [AssetType.ETF]: "ETF",
  [AssetType.StructuredProduct]: "Strategies",
};

export enum OrderType {
  Market = "market",
  Limit = "limit",
}

export enum OperationType {
  Buy = "buy",
  Sell = "sell",
}

export enum OperationStatus {
  Actively = 1, // Активно
  Completed = 2, // Исполнено
  Canceled = 3, // Отменено
  CancellationRequest = 4, // Запрос На Отмену
  OnPerformance = 5, // На Исполнении
  Error = 6, // Ошибка
  Refusal = 7, // Отказ
  Expired = 8, // Истек Срок
  PartiallyCompleted = 9, // Частично Исполнено
  NotExecuted = 10, // Не Исполнено
  WithdrawnClients = 11, // Отозвано Клиентов
}
