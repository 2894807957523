import { ErrorMessage, Field } from "formik";
import React from "react";
import "./style.scss";

type PropsType = {
  label: string;
  id: string;
  type: string;
  isInvalid?: boolean;
  placeholder?: string;
  customClass?: string;
  autoFocus?: boolean;
  customField?: boolean;
  autoComplete?: string | undefined;
  additionalFieldClass?: string;
};
const FormField: React.FC<PropsType> = ({
  label,
  id,
  type,
  isInvalid,
  placeholder = "",
  customClass,
  autoFocus,
  customField,
  autoComplete,
  additionalFieldClass = "",
}) => {
  return (
    <div className={`${customClass} form-row`}>
      <label htmlFor={id}>{label}</label>
      {customField ? (
        <input
          type={type}
          id={id}
          placeholder={placeholder}
          className={customClass}
          autoFocus={autoFocus}
        />
      ) : (
        <Field
          type={type}
          name={id}
          id={id}
          className={
            additionalFieldClass + " " + isInvalid ? "input-error" : ""
          }
          placeholder={placeholder}
          autoComplete={autoComplete}
          autoFocus={autoFocus}
        />
      )}
      {!customField && (
        <ErrorMessage name={id} component="span" className="error" />
      )}
    </div>
  );
};

export default FormField;
