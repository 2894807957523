import React, { useCallback, useEffect, useRef, useState } from "react";
import "./style.scss";
import { Stories } from "../components/stories-card";
import BalanceUp from "../../../assets/BalanceUp.svg";
import BalanceDown from "../../../assets/BalanceDown.svg";
import RatesSettings from "../../../assets/RatesSettings.svg";
import HelpIcon from "../../../assets/HelpCircle.svg";
import ProgresFrame from "../../../assets/ProgressFrame.svg";
import ProgresFlag from "../../../assets/ProgressFlag.svg";
import ShowBalance from "../../../assets/ShowBalance.svg";
import HideBalance from "../../../assets/HideBalance.svg";
import TopUp from "../../../assets/TopUp.svg";
import Marquee from "react-fast-marquee";
import { PartfolioItem } from "../components/portfolio-card";
import { AccountItem } from "../components/accounts-card";
import { AdviserModal } from "../components/adviser-modal";
import { useLocation, useNavigate } from "react-router-dom";
import { PATH_LIST } from "shared/lib/react-router";
import { AddAccountCurrency } from "../components/add-account-currency";
import { useStore } from "zustand";
import {
  IUserBrokerageAccount,
  dashboardStore,
  useCurrencies,
} from "entities/dashboard";
import { AssetType, EntityType } from "static/types";
import { CURRENCY_FLAGS } from "static/currencies";
import { useBlockScrollbar } from "hooks/useBlockScrollbar";
import {
  convertTo,
  formatPrice,
  getMLValue,
  getRatePrice,
  getStoryImgUrl,
  navigateToAsset,
} from "helpers/functions";
import StoriesModal from "../stories";
import HorizontalScroll from "../components/horizontal-scroll";
import { useTranslation } from "react-i18next";
import { ioRTStore } from "services/rt.socket";
import { Spinner } from "widgets/loading";
import { marketStore } from "entities/market";
import { AssetsContainer } from "../market";
import Modal from "widgets/auth/modal";
import { accountStore, KYC_Status } from "entities/account";
import { sessionStore } from "entities/session/model/sessionModel";

interface IModalText {
  info: string;
  findOut: string;
  improve: string;
}
const AdviserModalText: IModalText = {
  info: "adviserInfo",
  findOut: "adviserFindOut",
  improve: "adviserImprove",
};

export const HomePage = () => {
  const [play, setPlay] = useState(true);
  const [modalAdviser, setModalAdviser] = useState<keyof IModalText | null>(
    null
  );
  const [addAccCurrency, setAddAccCurrency] = useState(false);
  const [storiesModal, setStoriesModal] = useState(false);
  const [storiesIndex, setStoriesIndex] = useState(0);
  const [loadPortfolio, setLoadPortfolio] = useState(false);
  const { i18n, t } = useTranslation("app");

  const { exchanges } = useStore(marketStore);
  const { connected, list, subscribe, unsubscribe } = useStore(ioRTStore);
  const [page, setPage] = useState(0);
  const { entity } = useStore(sessionStore);
  const { kyc, kyb } = useStore(accountStore);

  const location = useLocation();
  const [welcomModal, setWelcomModal] = useState(
    location.state?.welcomeModal || false
  );

  useBlockScrollbar(!!modalAdviser || addAccCurrency || storiesModal, [
    modalAdviser,
    addAccCurrency,
    storiesModal,
  ]);

  useEffect(() => {
    localStorage.setItem("play", play.toString());
  }, [play]);

  const {
    user_deposit_accounts,
    user_brokerage_accounts,
    user_non_trading_accounts,
    user_accounts_show_config,
    all_rates,
    user_rates,
    loadCurrencies,
    loadAllRates,
    loadUserAccounts,
    currencies,
    balanceCurrency,
    setBalanceCurrency,
    stories,
    loadStories,
  } = useStore(dashboardStore);

  const navigate = useNavigate();

  const handleNavigateEditRates = () => {
    navigate(PATH_LIST.editRates);
  };
  const handleNavigatePortfolio = () => {
    navigate(PATH_LIST.portfolio);
  };

  const handleNavigateMarket = () => {
    navigate(PATH_LIST.market);
  };

  const changeExchangeRate = useCallback(() => {
    if (balanceCurrency === "USD") {
      setBalanceCurrency("RUB");
    } else if (balanceCurrency === "RUB") {
      setBalanceCurrency("AMD");
    } else if (balanceCurrency === "AMD") {
      setBalanceCurrency("EUR");
    } else {
      setBalanceCurrency("USD");
    }
  }, [balanceCurrency, currencies]);

  const handleCloseModal = () => {
    setTimeout(() => {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }, 5);
    setAddAccCurrency(false);
  };

  useEffect(() => {
    setLoadPortfolio(true);
    loadUserAccounts().then(() => {
      setLoadPortfolio(false);
    });
    loadStories();
  }, []);

  const getUserAccount = useCallback(() => {
    return Object.entries(user_accounts_show_config)
      .filter(([key, value]) => !!value)
      .map(([key]) => {
        const acc = user_deposit_accounts.find(
          (acc) => acc.currency.code === key
        );
        return {
          symbol: key,
          amount: acc?.amount || 0,
        };
      });
  }, [user_deposit_accounts, user_accounts_show_config]);

  const getUserBrokerageAccount = useCallback(() => {
    return [
      ...user_brokerage_accounts
        .filter((a) => a.quantity > 0)
        .map((v) => ({ ...v, nonTrading: false })),
      ...user_non_trading_accounts
        .filter((a) => a.quantity > 0)
        .map((v) => ({ ...v, nonTrading: true })),
    ];
  }, [user_brokerage_accounts, user_non_trading_accounts]);

  useEffect(() => {
    if (!connected) return;

    const symbols = (getUserBrokerageAccount() || [])
      .filter((a) => [AssetType.Stock, AssetType.ETF].includes(a.asset.type))
      .map((a) => {
        const ex = a.asset.exchange;
        return ex ? `${ex.symbol}~${a.asset.symbol}` : a.asset.symbol;
      });

    if (!storiesModal) {
      for (const s of symbols) {
        subscribe(s);
      }
    }

    return () => {
      for (const s of symbols) {
        unsubscribe(s);
      }
    };
  }, [connected, storiesModal, getUserBrokerageAccount]);

  const getCurrencySymbol = useCallback(
    (currency: string) => {
      const item = currencies.find((v) => v.code === currency);
      if (item) {
        return item.symbol;
      } else {
        return "";
      }
    },
    [currencies]
  );

  const getAssetQuote = useCallback(
    (account: IUserBrokerageAccount) => {
      const { asset, quantity, price } = account;
      const { exchange: ex, symbol, ask, bid, data, type } = asset;
      const rtPrice = list[ex ? `${ex.symbol}~${symbol}` : symbol];

      if ([AssetType.Stock, AssetType.ETF, AssetType.Fond].includes(type)) {
        const quote = rtPrice
          ? +((rtPrice.bid + rtPrice.ask) / 2).toFixed(2)
          : +((bid + ask) / 2).toFixed(2) || 0;
        if (quote) {
          const i = price / 100;
          const diff = +(quote - price).toFixed(2);
          return {
            price: quote,
            progress: +(diff / i).toFixed(2),
          };
        } else {
          return {
            price: +price.toFixed(2),
            progress: 0,
          };
        }
      } else if ([AssetType.Bond, AssetType.EuroBond].includes(type)) {
        const quote =
          parseFloat(
            (((ask + bid) / 2 / 100) * data.nominal + data.ai).toFixed(2)
          ) || 0;
        if (quote) {
          const i = price / 100;
          const diff = +(quote - price).toFixed(2);
          return {
            price: quote,
            progress: +(diff / i).toFixed(2),
          };
        } else {
          return {
            price: +price.toFixed(2),
            progress: 0,
          };
        }
      } else {
        return {
          price: +price.toFixed(2),
          progress: 0,
        };
      }
    },
    [list, exchanges]
  );

  const [hide, setHide] = useState(false);

  useEffect(() => {
    const hidenBalanceState = localStorage.getItem("hide");
    if (hidenBalanceState !== null) {
      setHide(hidenBalanceState === "true");
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("hide", hide.toString());
  }, [hide]);

  const getUserBalance = useCallback(
    (currency: string) => {
      let balance = 0;
      let balanceByMarket = 0;

      if (user_deposit_accounts.length) {
        balance += user_deposit_accounts
          .map(({ currency: curr, amount }) =>
            convertTo(
              all_rates,
              currency
            )({
              symbol: curr.code,
              amount,
            })
          )
          .reduce((prev, curr) => prev + curr, 0);
        balanceByMarket += user_deposit_accounts
          .map(({ currency: curr, amount }) =>
            convertTo(
              all_rates,
              currency
            )({
              symbol: curr.code,
              amount,
            })
          )
          .reduce((prev, curr) => prev + curr, 0);
      }

      if (user_brokerage_accounts.length) {
        balance += user_brokerage_accounts
          .map(({ asset, price, quantity }) =>
            convertTo(
              all_rates,
              currency
            )({
              symbol: asset.currency.code,
              amount: price * quantity,
            })
          )
          .reduce((prev, curr) => prev + curr, 0);
        balanceByMarket += user_brokerage_accounts
          .map(({ asset, quantity, price }) =>
            convertTo(
              all_rates,
              currency
            )({
              symbol: asset.currency.code,
              amount:
                getAssetQuote({
                  asset,
                  quantity,
                  price,
                } as IUserBrokerageAccount).price * quantity,
            })
          )
          .reduce((prev, curr) => prev + curr, 0);
      }

      const symbol = getCurrencySymbol(currency);
      const isUp = balanceByMarket >= balance;

      const i = balance / 100;
      const diff = balanceByMarket - balance;
      const percent = +(diff / i || 0).toFixed(2);
      const progress = +diff.toFixed(2);

      return (
        <div
          className={
            !hide ? "available-balance" : "available-balance hiden-balance"
          }
          onClick={!hide ? changeExchangeRate : undefined}
        >
          <img
            className="show-hide-balance"
            onClick={(e) => {
              e.stopPropagation();
              setHide(!hide);
            }}
            src={hide ? HideBalance : ShowBalance}
            alt="Show/Hide"
          />

          <span>{t("availableBalance")}</span>

          {loadPortfolio && !balanceByMarket ? (
            <Spinner style={{ margin: "24px" }} />
          ) : (
            <>
              <div className="available-balance-row">
                <p>
                  {/* {+balanceByMarket.toFixed(2)} {symbol}
                  <br/> */}
                  {formatPrice(balanceByMarket) || 0} {symbol}
                </p>
                <img
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    navigate(PATH_LIST.deposit);
                  }}
                  src={TopUp}
                  alt="Top-up"
                />
              </div>

              {balanceByMarket ? (
                <div className="balance-progres">
                  <img src={isUp ? BalanceUp : BalanceDown} alt="vector" />
                  <p>
                    {progress} {symbol}
                  </p>
                  <span className={`${isUp ? "" : "red"}`}>
                    {isUp ? "+" : ""}
                    {percent}%
                  </span>
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      );
    },
    [
      loadPortfolio,
      hide,
      all_rates,
      balanceCurrency,
      user_deposit_accounts,
      user_brokerage_accounts,
      getAssetQuote,
    ]
  );

  const navigateVerification = () => {
    if (entity == EntityType.Individual) {
      if (kyc) {
        if (kyc.status === KYC_Status.Pending) {
          setWelcomModal(true);
        }
      } else {
        navigate(PATH_LIST.citizenship);
      }
    } else {
      if (kyb) {
        if (kyb.status === KYC_Status.Pending) {
          setWelcomModal(true);
        }
      } else {
        navigate(PATH_LIST.citizenship);
      }
    }
  };

  const handleStoryIndexChange = (newIndex: number) => {
    setStoriesIndex(newIndex);
  };

  return (
    <div className="home">
      <HorizontalScroll className="stories">
        {stories.map(
          (
            { id, title, preview_image, use_multi_lang_images, preview_images },
            i
          ) => (
            <Stories
              onClick={() => {
                setStoriesModal(true);
                setStoriesIndex(i);
              }}
              key={id}
              info={getMLValue(title, i18n.language)}
              img={getStoryImgUrl(
                use_multi_lang_images
                  ? getMLValue(preview_images, i18n.language)
                  : preview_image
              )}
            />
          )
        )}
      </HorizontalScroll>
      {getUserBalance(balanceCurrency)}
      <div className="home-currency-section">
        <div className="home-currency-section-header">
          <p>{t("Currencies")}</p>
        </div>
        <div className="home-currency-wrapper">
          <AssetsContainer currencies page={page} setPage={setPage} />
        </div>
      </div>
      <div className="accounts">
        <div className="accounts-container">
          <div className="accounts-header">
            <p>{t("accounts")}</p>
            <button onClick={() => setAddAccCurrency(true)}>{t("add")}</button>
          </div>
          {loadPortfolio ? (
            <p className="assets-loading">{t("loading")}</p>
          ) : (
            <>
              {getUserAccount().map(({ symbol, amount }, i) => (
                <AccountItem
                  key={i}
                  img={CURRENCY_FLAGS[symbol]}
                  name={symbol}
                  price={+amount.toFixed(2)}
                  hidden={hide}
                  onClick={() => {
                    hide && setHide(false);
                  }}
                />
              ))}
            </>
          )}
        </div>
      </div>
      <div className="portfolio">
        <div className="portfolio-header">
          <p>{t("portfolio")}</p>
          {!!getUserBrokerageAccount().length && (
            <button onClick={handleNavigatePortfolio}>{t("seeAll")}</button>
          )}
        </div>
        <div className="portfolio-cards">
          {loadPortfolio ? (
            <p className="assets-loading">{t("loading")}</p>
          ) : !getUserBrokerageAccount().length ? (
            <div className="empty-portfolio">
              <p>{t("emptyPortfolio")}</p>
              <span onClick={handleNavigateMarket}>{t("goToMarket")}</span>
            </div>
          ) : (
            <>
              {getUserBrokerageAccount()
                .slice(0, 4)
                .map((account) => (
                  <PartfolioItem
                    asset={account}
                    nonTrading={account.nonTrading}
                    key={account.asset.id}
                    price={getAssetQuote(account).price}
                    progress={getAssetQuote(account).progress}
                    onClick={() => {
                      if (account.asset.type === AssetType.StructuredProduct) {
                        navigate(PATH_LIST.strategy(account.asset.id));
                      } else {
                        navigateToAsset(
                          account.asset.type,
                          account.asset.id,
                          navigate
                        );
                      }
                    }}
                  />
                ))}
            </>
          )}
        </div>
      </div>
      <>
        {/* //todo / ADVISER IS CLOSED / */}
        {/* <div className="adviser">
        <div className="adviser-container">
          <div className="adviser-header">
            <p>{t("adviser")}</p>
            <img
              onClick={() => setModalAdviser("info")}
              src={HelpIcon}
              alt="Help-Icon"
            />
          </div>
          <div className="adviser-main">
            <img className="flag" src={ProgresFlag} alt="Progres-Flag" />
            <img
              className="progress-frame"
              src={ProgresFrame}
              alt="Progres-Frame"
            />
          </div>
          <div className="adviser-footer">
            <button onClick={() => setModalAdviser("findOut")}>
              {t("findOut")}
            </button>
            <button onClick={() => setModalAdviser("improve")}>
              {t("improve")}
            </button>
          </div>
        </div>
      </div> */}
        {/* {modalAdviser && (
        <AdviserModal
          title={t("adviser")}
          text={t(AdviserModalText[modalAdviser])}
          btnText={t("gotIt")}
          onClick={() => setModalAdviser(null)}
          onHide={() => setModalAdviser(null)}
        />
      )} */}
      </>
      {addAccCurrency && (
        <AddAccountCurrency onClose={() => handleCloseModal()} />
      )}

      {storiesModal && (
        <StoriesModal
          currentStoryIndex={storiesIndex}
          onAllStoriesEnd={() => setStoriesModal(false)}
          onStoryIndexChange={handleStoryIndexChange}
        />
      )}

      {welcomModal && (
        <Modal
          customModal="modalLogOut welcomeModal"
          onClick={() => {
            window.history.replaceState({}, "");
            navigateVerification();
          }}
          onHide={() => {
            window.history.replaceState({}, "");
            setWelcomModal(false);
          }}
          title={t("welcome")}
          text={t("toFullyActivate")}
          btnText={t("completeRegistration")}
          showSecondBtn={false}
          customOutlet="customOutlet"
        />
      )}
    </div>
  );
};
